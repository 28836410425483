import { useEffect, useState, useRef } from "react";

import {
    Box,
    Container,
    Heading,
    // SimpleGrid,
    Flex,
    // Icon,
    Text,
    Stack,
    // HStack,
    // VStack,
    Fade
  } from '@chakra-ui/react';
  // import { CheckIcon } from '@chakra-ui/icons';
  
  import TimelineObserver from "react-timeline-animation";


  // Replace test data with your own
//   const features = Array.apply(null, Array(8)).map(function (x, i) {
//     return {
//       id: i,
//       title: 'Lorem ipsum dolor sit amet',
//       text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.',
//     };
//   });

  // const features: any[] = [
  //   {
  //       id: 0,
  //       title: 'Prelease',
  //       text: '666 Original Grumpy Dragons get released',
  //   },
  //   {
  //       id: 1,
  //       title: 'Free drop Event',
  //       text: 'Special Grumpy Dragons get dropped for free.',
  //   },
  //   {
  //       id: 2,
  //       title: 'Grumpy Dragon Release',
  //       text: '6,000 Grumpy Dragons get released',
  //   },
  //   {
  //       id: 3,
  //       title: 'Dragon T-shirts',
  //       text: 'Member-Exclusive Grumpy Dragon Store gets unlocked, featuring Limited Edition tees, hoodies, and other goodies.',
  //   },
  //   {
  //       id: 4,
  //       title: 'Dragon Townhall',
  //       text: 'Grumpy Dragon Society members meet, do some games, and have some fun.',
  //   },
  //   {
  //       id: 5,
  //       title: `Dragon's palace`,
  //       text: `We're developing a member-exclusive app to hold events, communicate, and collaborate.`,
  //   }
  // ]
  
  const Timeline = (props: any) => {
    const { setObserver } = props;
    const [message1, setMessage1] = useState(false);
    const [message2, setMessage2] = useState(false);
    const [message3, setMessage3] = useState(false);
    const [message4, setMessage4] = useState(false);
    const [message5, setMessage5] = useState(false);
    const [message6, setMessage6] = useState(false);
    const timeline1 = useRef(null);
    const timeline2 = useRef(null);
    const timeline3 = useRef(null);
    const timeline4 = useRef(null);
    const timeline5 = useRef(null);
    const timeline6 = useRef(null);
    const circle1 = useRef(null);
    const circle2 = useRef(null);
    const circle3 = useRef(null);
    const circle4 = useRef(null);
    const circle5 = useRef(null);
    const circle6 = useRef(null);
    const someCallback = () => {
      setMessage1(true);
      // callback();
    };
  
    const someCallback2 = () => {
      setMessage2(true);
    };
  
    const someCallback3 = () => {
      setMessage3(true);
      // fireConfetti();
    };
  
    const someCallback4 = () => {
      setMessage4(true);
      // callback();
    };
  
    const someCallback5 = () => {
      setMessage5(true);
    };
  
    const someCallback6 = () => {
      setMessage6(true);
      // fireConfetti();
    };

    useEffect(() => {
      setObserver(timeline1.current);
      setObserver(timeline2.current);
      setObserver(timeline3.current);
      setObserver(timeline4.current);
      setObserver(timeline5.current);
      setObserver(timeline6.current);
      setObserver(circle1.current, someCallback);
      setObserver(circle2.current, someCallback2);
      setObserver(circle3.current, someCallback3);
      setObserver(circle4.current, someCallback4);
      setObserver(circle5.current, someCallback5);
      setObserver(circle6.current, someCallback6);
    }, [setObserver]);
  
    return (
      <div className="wrapper">
        <div id="timeline1" ref={timeline1} className="timeline" />
        <div className="circleWrapper">
          <div id="circle1" ref={circle1} className="circle">
            1
          </div>
          <Fade in={message1}>
          <div className="rmessage">Build Community</div>
            <div className="rbox">
              Discord, Twitter, Website 1.0 Launch
            </div>
          </Fade>
        </div>
        <div id="timeline2" ref={timeline2} className="timeline" />
        <div className="circleWrapper">
          <div id="circle2" ref={circle2} className="circle">
            2
          </div>
          <Fade in={message2}>
          <div className="lmessage">Wave 1.0</div>
            <div className="lbox">
              777 unique dragon oppas released into the East Sea
            </div>
          </Fade>
        </div>
        <div id="timeline3" ref={timeline3} className="timeline" />
        <div className="circleWrapper">
          <div id="circle3" ref={circle3} className="circle">
            3
          </div>
          <Fade in={message3}>
            <div className="rbox">
              1,000 unique dragon oppas released into the South Sea
            </div>
            
            <div className="rmessage">Wave 2.0</div>
          </Fade>
        </div>
        <div id="timeline4" ref={timeline4} className="timeline" />
        <div className="circleWrapper">
          <div id="circle4" ref={circle4} className="circle">
            4
          </div>
          <Fade in={message4}>
            <div className="lmessage">Wave 3.0</div>
            <div className="lbox">
              1,000 unique dragon oppas released into the West Sea
            </div>
          </Fade>
        </div>
        <div id="timeline5" ref={timeline5} className="timeline" />
        <div className="circleWrapper">
          <div id="circle5" ref={circle5} className="circle">
            5
          </div>
          <Fade in={message5}>
            <div className="rmessage">Wave 4.0</div>
            <div className="rbox">
              1,000 unique dragon oppas released into the North Sea
            </div>
          </Fade>
        </div>
        <div id="timeline6" ref={timeline6} className="timeline" />
        <div className="circleWrapper">
          <div id="circle6" ref={circle6} className="circle">
            6
          </div>
          <Fade in={message6}>
            <div className="lmessage">Phase 1.0 Finale</div>
            <div className="lbox">
              Get listed on the secondary marketplaces
            </div>
          </Fade>
        </div>
      </div>
    );
  };

  export default function Roadmap() {
    // const [message, setMessage] = useState("");

    // const onCallback = () => {
    //   console.log("awesome");
    // };
    return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" minH={'100vh'}>
      <Box>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading>The roadmap</Heading>
          <Text color={'gray.600'} fontSize={'large'} textAlign={'left'}>
            In the time of darkness, when the metaverse is dominated by the gangs and mutants, the dragon emperor prays for the balance of Yin and Yang. 3,777 Dragons oppas are released to restore the yin yang balance of this metaverse. Check out our roadmap for the path to ying & yang balance.            
          </Text>
        </Stack>
      </Box>
      <Box marginTop={45}>
        <TimelineObserver
          initialColor="#e5e5e5"
          fillColor="blue"
          handleObserve={(setObserver) => (
            <Timeline
              // callback={onCallback}
              className="timeline"
              setObserver={setObserver}
            />
          )}
        />
      </Box>
      {/* <Box p={4}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading>The roadmap</Heading>
          <Text color={'gray.600'} fontSize={'xl'}>
            We’re in this for the long journey.
            We’ve set up some goalposts for ourselves. Once we hit a target sell through percentage, we will begin to work on realizing the stated goal.
          </Text>
        </Stack> */}
  
        {/* <Container maxW={'6xl'} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={'top'}>
                <Box color={'green.400'} px={2}>
                  <Icon as={CheckIcon} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>{feature.title}</Text>
                  <Text color={'gray.600'}>{feature.text}</Text>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>  */}
    </Flex>
    );
  }