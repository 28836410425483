import {
    Box,
    Center,
    Image,
  } from '@chakra-ui/react';

export interface ArtProps {
    image: any,
    width: number,
    height: number
}

      
  export default function Art(props: ArtProps) {
    const { image, width, height } = props; 
    return (
      <Center py={12}>
          <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={height-50 + 'px'}
            _after={{
              transition: 'all .3s ease',
              content: '""',
              w: 'full',
              h: 'full',
              pos: 'absolute',
              top: 5,
              left: 0,
              backgroundImage: `url(${image})`,
              filter: 'blur(15px)',
              zIndex: -1,
            }}
            _groupHover={{
              _after: {
                filter: 'blur(20px)',
              },
            }}>
            <Image
              rounded={'lg'}
              height={height}
              width={width}
              objectFit={'cover'}
              src={image}
            />
          </Box>
      </Center>
    );
  }