import React, { useEffect, useRef } from "react";

import {
  Container,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
  Button,
  IconProps,
  SimpleGrid,
  // Image,
  Link
} from '@chakra-ui/react';
import lottie from "lottie-web";
import laternLottie from "../assets/lotties/newyearlantern.json";

import Art from './Art';

import GrumpyDragonCowboy from '../assets/images/gd9.png';
import GrumpyDragonHanbok from '../assets/images/gd5.png';
import GrumpyDragonNun from '../assets/images/gd8.png';
import GrumpyDragonCook from '../assets/images/gd4.png';
import ChineseRoof from '../assets/images/AsianRoof.png';

import doA from '../assets/images/gd1.png';
import doB from '../assets/images/gd2.png';
import doC from '../assets/images/gd3.png';
import doD from '../assets/images/gd4.png';
import doE from '../assets/images/gd5.png';
import doF from '../assets/images/gd6.png';
import doG from '../assets/images/gd7.png';
import doH from '../assets/images/gd8.png';
import doJ from '../assets/images/gd9.png';
import doK from '../assets/images/gd10.png';
import doL from '../assets/images/gd11.png';
import doM from '../assets/images/gd12.png';
import doN from '../assets/images/gd13.png';
import doO from '../assets/images/gd14.png';
import doP from '../assets/images/gd15.png';
// import doQ from '../assets/images/gd16.png';
// import doR from '../assets/images/gd17.png';
// import doS from '../assets/images/gd18.png';
// import doT from '../assets/images/gd19.png';
// import doU from '../assets/images/gd20.png';

export default function Hero(props: any) {
  const laternOne = useRef<HTMLDivElement>(null);
  // const lanternTwo = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(laternOne.current){
      const anim = lottie.loadAnimation({
        container: laternOne.current,
        animationData: laternLottie,
        loop: false,
      });
      lottie.setSpeed(0.5);

      const timer1 = setTimeout(()=>anim.pause(), 3000);
      return () => {
        clearTimeout(timer1)
      }
    }


  }, [laternOne]);

  // useEffect(() => {
  //   if(lanternTwo.current)
  //     lottie.loadAnimation({
  //       container: lanternTwo.current,
  //       animationData: laternLottie
  //     });
  // }, [lanternTwo]);
  
  return (
    <Flex alignItems="center" justifyContent="center"
        w={'full'}
        minH={'100vh'}
    >
    {/* <div style={{position: 'absolute', top: 0, height: 600}} ref={laternOne} /> */}
    {/* <div style={{position: 'absolute', top: 0, left: '15%', height: 100}} ref={laternOne} /> */}
    {/* <div style={{position: 'absolute', top: 0, right: '15%', height: 100}} ref={lanternTwo} /> */}
    <img src={ChineseRoof} alt={'roof'} id={'roof'} />
    <Container maxW={'5xl'} paddingTop={0}>
      <Stack
        paddingTop={0}
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 12, md: 10 }}
        py={{ base: 20, md: 28 }}
        
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '4xl', sm: '6xl', md: '6xl' }}
          lineHeight={'110%'}>
          Welcome to the{' '}
          <br /> 
          <Text as={'span'} fontWeight={900}>
            Dragon Oppa Club
          </Text>
        </Heading>
        <Text color={'gray.500'} fontSize={'xl'} maxW={'3xl'}>
          A limited NFT collection on the Solana blockchain. <br /> Join dragon oppas to restore the Yin and Yang ☯️ balance of this metaverse.
        </Text>
        <Stack spacing={6} direction={'row'}>
          <Link href='https://mint.dragonoppaclub.com/'>
            <Button
              rounded={'full'}
              px={6}
              colorScheme={'blue'}
              bg={'blue'}
              size='lg'
              _hover={{ bg: 'darkblue' }}
              // onClick={props.scrollToMint}
              >
              Mint Now
            </Button>
          </Link>
          <Button
            rounded={'full'}
            size='lg'
            px={6}
            onClick={props.scrollToMore}
          >
            Learn more
          </Button> 
        </Stack>
        <Center>
          <Slider />
        </Center>
      </Stack>
    </Container>
    </Flex>
  );
}

export const Illustration = (props: IconProps) => {
  return (
  <SimpleGrid
    columns={2}
    spacing={5}
  >
    <Art width={160} height={160} image={GrumpyDragonCowboy} />
    <Art width={160} height={160} image={GrumpyDragonHanbok} />
    <Art width={160} height={160} image={GrumpyDragonNun} />
    <Art width={160} height={160} image={GrumpyDragonCook} />

  </SimpleGrid>
  );
};

export const Slider = (props: IconProps) => {
  return (
    <div className="slider">
      <div className="slide-track">
        <div className="slide">
          <Art width={350} height={280} image={doA} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doB} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doC} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doD} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doE} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doF} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doG} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doH} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doJ} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doK} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doL} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doM} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doN} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doO} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doP} />
        </div>
        {/* <div className="slide">
        <Art width={280} height={280} image={doQ} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doR} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doS} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doT} />
        </div>
        <div className="slide">
        <Art width={280} height={280} image={doU} />
        </div> */}
      </div>
    </div>
  );
};
