import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Box,
    Container,
    Text,
    Flex,
    Heading,
    Stack,
  } from '@chakra-ui/react';
  
  export default function Faq() {
    return (
    <Flex alignItems="center" justifyContent="center"
        w={'full'}
        minH={'100vh'}
    >
        <Stack spacing={4} as={Container} maxW={'5xl'} textAlign={'center'} >
          <Heading>FAQ</Heading>
          <Accordion allowMultiple>
            {faqData.map(faq=> {
                return <AccordionItem>
                {({ isExpanded }) => (
                <>
                    <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            <Text color={'black'} fontSize={'lg'}>
                                {faq.title}
                            </Text>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Text color={'black'} fontSize={'lg'}>
                            {faq.content}
                        </Text>
                    </AccordionPanel>
                </>
                )}
                </AccordionItem>
            })}
            
            </Accordion>
        </Stack>
      </Flex>
    );
  }

  const faqData = [
      {
        title: "How many Dragon Oppas will be available for sale?",
        content: "A total of 3,777 unique Dragon Oppas will be created."
      },
      {
        title: "When will Dragon Oppa Club launch?",
        content: "February 22nd 2022"
      },
      {
        title: "What is the Mint price?",
        content: "Purchasing an dragon oppa costs 0.33SOL."
      },
      {
        title: "How does the mint event look like?",
        content: "There will be four different mint waves for phase 1. Whitelisted members will get priority to claim a Dragon Oppa, and the dates for the next waves will be randomly dropped through Discord and Twitter."
      },
      {
        title: "Why Dragon Oppa Club?",
        content: "When you join the Dragon Oppa Club, you own one of 3,777 tokens of limited community. Our roadmap is quarterly based, with new and innovative goals and roadmap each quarter term."
      },
      {
        title: "How do I get a Dragon Oppa?",
        content: "You can join each mint wave to get Dragon Oppas. If you miss one wave, the next one will be there soon. Just keep your eyes on it and you will get a dragon oppa 🙂"
      },
      {
        title: "Intellectual property rights?",
        content: "Ownership and commercial usage rights will be given to buyers."
      },
      {
        title: "How does your roadmap for phase2 and phase3 look like?",
        content: "We don’t have any specific plans about our roadmap for the next phases yet. While creating the concept of Dragon Oppa Club, we had many thoughts about our future. We thought about having our street brand t-shirts and hoodies. We thought about setting up a KPOP radio channel to share music. We thought about building a fun dapp for our members. We also thought about having a dragon hotel room where any member has a proprietary access. But honestly speaking, while we’re excited about what could be possible, we didn’t want to over promise and lie about our plans. First and more importantly, we want to build a community around Asian culture. We welcome anyone with an interest to join this community and develop this into something really awesome."
      }
];