import {
    Box,
    chakra,
    Flex,
    Text,
    SimpleGrid
  } from '@chakra-ui/react';
  
  import Card from './Card';

  import DragonOppaOne from '../assets/images/gm20.jpg';
  import DragonOppaTwo from '../assets/images/gm21.jpg';
  import DragonOppaThree from '../assets/images/gm22.jpg';
  import DragonOppaFour from '../assets/images/gm23.jpg';
  import DragonOppaFive from '../assets/images/gm24.jpg';
  import DragonOppaSix from '../assets/images/gm25.jpg';

  export default function Team() {
    return (
      <Flex alignItems="center" justifyContent="center"
        w={'full'}
        minH={'100vh'}
        marginTop={'300'}
      >
        <Box maxW="4xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
          <chakra.h1
            textAlign={'center'}
            fontSize={'4xl'}
            py={10}
            fontWeight={'bold'}>
            The team
          </chakra.h1>
          <Text color={'gray.600'} fontSize={'xl'}>
          </Text>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 3 }}>
            <Card title="Co-founder, Designer" name="Malawhoo" image={DragonOppaTwo} />
            <Card title="Co-founder, Engineer" name="Kimchihead" image={DragonOppaOne} />
            <Card title="Community Manager" name="MotionB" image={DragonOppaThree} />
            <Card title="Community Moderator" name="KrizBarz" image={DragonOppaFour} />
            <Card title="Moderator, Shiller" name="IamTwest" image={DragonOppaFive} />
            <Card title="Community Hustler" name="Fullda" image={DragonOppaSix} />
          </SimpleGrid>
        </Box>
      </Flex>
    );
  }